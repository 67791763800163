<template>
  <v-card>
    <v-card-title>分類テーブル</v-card-title>
    <v-text-field v-model="search" label="分類マスタ内文字検索" append-icon="mdi-magnify"></v-text-field>
  <v-data-table :headers="headers" :items="items" :items-per-page="15" loading-text="読込中" :search="search" :custom-filter="filterOnlyCapsText"/>
 </v-card>
</template>
<script>
import axios from 'axios';
export default {
  data() {
    return {
      search: '',
      items: [],
      headers: [
          { text: 'BRMSDCD',  value: 'BRMSDCD'},
          { text: 'BRMSCD', value: 'BRMSCD'},
          { text: 'BRMSNM', value: 'BRMSNM'},
          { text: 'BRMSJYUN', value: 'BRMSJYUN'},
          { text: 'BRMSSK', value: 'BRMSSK'},
          { text: 'BRMSIMG', value: 'BRMSIMG'},
      ],
    }
  },
  //経路取得
  mounted: function() {
    const fomego = { BRMSDCD: '005'};
    axios.post('/index/BRMSALL',fomego)
      .then(response => {
        this.items = response.data
      })
      .catch(error => {
        console.log(error)
      })
    },
  
   methods: {
      filterOnlyCapsText (value, search) {
        return value != null &&
          search != null &&
          typeof value === 'string' &&
          value.toString().toLocaleUpperCase().indexOf(search) !== -1
      },
  }
}
</script>